.css-4jnixx-MuiStack-root > .MuiTextField-root {
  min-width: 215px !important;
}

.main-chip {
  margin-right: 10px !important;
  border-radius: 4px !important;
}

.custom-margin {
  margin-right: 10px;
}

.add-time-button {
  padding: 10px !important;
  border-radius: 20px;
}

.add-time-padding {
  padding-left: 3px;
  margin-top: 10px;
}
/* 
.time-slot-details {
  background-color: aliceblue;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
} */

.delete-time-slot {
  display: flex;
  align-items: center;
  margin-top: 34px;
}

.css-1q04gal-MuiDateCalendar-root {
  max-height: 290px !important;
}

.css-4iyyks-MuiFormControl-root-MuiTextField-root {
  border: none;
}

/* Styles for the time-slot-details container */
.time-slot-details {
  position: relative; /* Needed for the absolute positioning of the Add button */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 16px;
  background-color: #f9f9f9;
}

/* Styles for the day header */
.day-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top:"2px";
}

/* Styles for the add button positioned at the right */
.add-time-slot {
  position: absolute;
  top: 6px;
  right: 8px;
}

/* Styles for individual time slot rows */
.time-slot-row {
  margin-bottom: 3px;
}

/* Padding for custom-margin class */
.custom-margin {
  margin-right: 16px;
}

.css-niqf4j-MuiStack-root{
  margin-bottom: 20px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  height: 0.4375em !important;
}

.time-slot-add-button{
  padding: 6px 10px !important;
}

@media only screen and (max-width: 516px) {
  .time-slot-row {
    margin-bottom: 3px;
    flex-direction: column;
  }
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 1.5px 14px !important
}

.css-1j0ybez{
  border: none !important
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8.5px 14px
}

.css-1uvydh2{
  padding: 8.5px 0px 9.5px 14px !important
}