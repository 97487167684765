.user-preference-info-card{
    padding: 20px !important;
    background-color: aliceblue !important;
    border-radius: 10px !important;
}

.css-1jzq0dw-MuiChip-label{
    font-weight: bold !important;
}

.user-preference-basic-info{
    padding: 10px;
    display: flex;
    background-color: aliceblue;
    border-radius: 10px;
    flex-direction: column;
}