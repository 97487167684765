
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/walrus');
html body {
  font-family: "Aptos", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px !important;
  background-color: #ecebdf;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding-right: 0 !important;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {

  font-family: 'Aptos', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.MuiDataGrid-columnHeaderTitle {
  color: #3f4254 !important;
  font-weight: 900 !important;
  font-size: 15px !important;

  font-family: "Aptos", sans-serif;
  text-transform: uppercase !important;
}

.MuiDataGrid-columnHeaderTitle:hover {
  color: var(--bs-gray-700) !important;
}

.MuiDataGrid-cellContent,
.MuiDataGrid-cell--withRenderer,
.table-loader-label {
  color: #3f4254 !important;
  font-family: "Aptos", sans-serif;
  font-size: 0.9rem !important;
  letter-spacing: 1.1px;
  font-weight: 500 !important;
}

input[type="time"] {
  display: inline-block;
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.rmsc .dropdown-container {
  position: relative !important;
  outline: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

input[type="date"] {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

#kt_app_header {
  background-color: #E0DEE7 !important;
  border-bottom: 1px solid #212121;
}

.flag-dropdown {
  background-color: #f9f9f9 !important;
  border: none !important;
}

.flag-dropdown:hover {
  background-color: #f9f9f9 !important;
}

.btn.btn-primary, .app-main .btn.btn-primary:hover:not(.btn-active) { background-color: #3D1A91; }
.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus, .btn.btn-primary:hover:not(.btn-active) { background: #F77148; color: #fff; }


.login-form { background-color: #F77148;border-radius: 30px;color: #f2f2f2; position: relative;}
.login-form h1 {color: #f2f2f2;font-size: 40px; font-family: "Aptos", sans-serif; font-weight: 900;}
.fs-24 {font-size: 24px;font-weight: 600;color: #f2f2f2;}
.login-form .btn.btn-primary, .login-form .btn.btn-primary:hover:not(.btn-active) { background-color: #3D1A91; color: #fff; }
.login-form .btn.btn-primary:hover, .login-form .btn.btn-primary:hover:not(.btn-active) { background-color: #fff !important; color: #F77148; }
.login-form .link-primary {padding-top: 1.2rem;padding-bottom: 1.2rem;}
.login-form .link-primary, .login-form .link-primary:hover, .login-form .link-primary:focus {color: #3D1A91 !important;}
.login-form .form-control {background-color: #f2f2f2;}
.login-form .form-check-input {border-color: #fff;}
.login-form .form-check-input:checked {background-color: #3D1A91; border-color: #3D1A91;}
.login-bg {position: fixed; left: 0; top: 0; width: 100%; height: 100%;}
.login-bg img {width: 100%; object-position: left; object-fit: cover; height: 100%;}

/* Added By Nilesh */
.bg-image-shape-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #3D1A91;
}
.login-bg-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  z-index: 1;
}
.login-shape {
  position: absolute;
  z-index: 2;
  width: 11.75rem;
  top: 0;
  left: 2.55rem;
}
.login-form-box {
  background: #212121;
  border-radius: 1rem;
  color: #ecebdf;
  padding: 0.8rem 3.5rem;

}
.logo-box {
  max-width: 240px;
  margin: 0 auto;
  width: 100%;
}
.logo-box figure {
  margin: 0;
}
.logo-box img {
  width: 100%;
  height: auto;
}
.login-form-box h1 {
  font-size: 2.25rem;
  color: #ecebdf;
}
div .form-control {
  background: #ecebdf;
  box-shadow: none;
  outline: none;
}

.app-sidebar-logo {  
  text-align: center;
  border-color: transparent !important;
}
.app-sidebar-logo a {
  display: table;
  margin: 0 auto;
}
.app-page,
.app-page .app-footer {
  background: #E0DEE7;
}
.page-heading {
  font-weight: 900;
  color: #212121;
}
.app-wrapper .app-sidebar {
  background: #3D1A91 !important;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-title {
  color: #fff;
  transition: 0.35s all;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active {
  background-color: #F77148;
  color: #f2f2f2;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-title {
  color: #fff;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-title,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-icon
  i {
  color: #F77148;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-title {
  color: #3D1A91 !important;
}
.app-sidebar-toggle {
  height: 24px;
  width: 54px;
  padding-left: 30px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.app-sidebar-toggle svg {
  width: 100%;
  height: auto;
}
.app-sidebar-toggle-ico svg,
.app-sidebar-toggle svg {
  fill: #212121;
}

.app-sidebar-logo .app-sidebar-toggle {
  background: transparent !important;
  box-shadow: none !important;
}
[data-kt-app-sidebar-fixed="true"] .app-wrapper {
  transition: 0.25s all;
}
.fmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.fmenu ul li a {
  color: #212121;
  text-decoration: none;
}
.fmenu ul li a:hover {
  color: #3D1A91;
}
.fmenu ul li {
  margin: 5px 30px 5px 0;
  line-height: 1;
}
.fmenu ul li:last-child {
  margin-right: 0;
}

.top-search {
  max-width: 300px;
  width: 100%;
  position: relative;
}
.top-search i {
  position: absolute;
  left: 1rem;
  bottom: 0.9rem;
  color: #9a9a9a;
}
.top-search i .path1:before {
  opacity: 1;
}
.top-search .form-control.form-control-solid {
  padding-left: 3.3rem;
  background: #ecebdf;
  border-color: #ecebdf;
  color: #212121;
}
.card .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell {
  font-size: 13px !important;
  border-color: transparent;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  justify-content: center;
}

.token-actions-align {
  font-size: 13px !important;
  border-color: transparent;
  padding: 0 1rem;
  display: flex !important;
  align-items: center !important;
  /* justify-content: flex-start; */
  justify-content: flex-end !important;
}

.css-1q04gal-MuiDateCalendar-root{
  max-height: 240px !important;
}

.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader
  .MuiDataGrid-columnHeaderTitle {
  color: #212121 !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 1.1rem !important;
  font-family: "Aptos", sans-serif;
  white-space: pre-wrap;
  line-height: normal;
}
.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader {
  border-color: transparent;
  padding: 0 1.5rem;
}
.card .MuiDataGrid-columnHeaders,
.card .MuiDataGrid-root .MuiDataGrid-footerContainer {
  border-color: transparent !important;
}
.card .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row {
  background: #E0DEE7;
}
.card .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:nth-child(2n + 2) {
  background: #fff;
}
.MuiTablePagination-root .MuiTablePagination-selectLabel,
.MuiTablePagination-root .MuiInputBase-root,
.MuiTablePagination-root .MuiTablePagination-displayedRows {
  color: #212121;
  font-size: 14px;
  font-family: "Aptos", sans-serif;
  font-weight: 500;
}
.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root {
  background: #ecebdf;
  color: #212121;
  margin-right: 10px;
}
.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root:hover {
  background: #3D1A91;
  color: #fff;
}
.MuiTablePagination-root
  .MuiTablePagination-actions
  .MuiButtonBase-root:last-child {
  margin-right: 0;
  align-items: center;
}

.MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
  color: #212121;
  font-size: 14px;
  font-family: "Aptos", sans-serif;
  font-weight: 500;
}
.app-content .form-select.form-select-solid,
.app-content .form-select.form-select-solid,
.app-content .form-control.form-control-solid {
  background-color: #E0DEE7;
  border-color: #E0DEE7;
  color: #666666;
}
.notification-ico {
  position: relative;
  margin: 0 1rem 0 0.5rem;
  cursor: pointer;
}
.notification-ico figure {
  height: 20px;
  width: 20px;
  margin: 0;
}
.notification-ico svg {
  width: 100%;
  height: auto;
  fill: #212121;
}
.notification-ico .count {
  position: absolute;
  top: -7px;
  right: -2px;
  height: 15px;
  width: 15px;
  background: #eb7627;
  color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 10px;
  text-align: center;
  line-height: 15px;
}
.app-container .card {
  position: relative;
  background-color: #f2f2f2;
}
.top-title {
  position: absolute;
  left: 1.5rem;
  right: 14rem;
  z-index: 5;
  top: 2.3rem;
}
.top-title h5 {
  margin: 0;
}
.filter-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.filter-btn { height: 40px; width: 40px; min-width: 40px; }
.filter-list ul li {
  margin: 0.5rem;
  background: #8081844D;
  padding: 0.7rem 2.6rem 0.6rem 1.3rem;
  border-radius: 100px;
  position: relative;
}
.filter-list ul li:first-child { margin-left: 0; }
.filter-close-ico {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.7rem;
  height: 1.4rem;
  width: 1.4rem;
  background: #ECEBDF;
  border: 1px solid #fff;
  border-radius: 50px;
  font-weight: bold;
  padding: 0;
  line-height: 1.2rem;
  text-align: center;
  cursor: pointer;
  display: block;
  transition: 0.35s all;
  color: #212121;
}
.filter-close-ico:hover { background: #3D1A91; border-color: #3D1A91; color: #fff; }
.dashboard-card-box.card {
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 30px 0px #2121210D;
    border-radius: 8px;
    z-index: 1;
    background: #fff;
    height: 100%;
    min-height: 260px;
}
.dashboard-card-box.card:hover { background: #3D1A91; }

.dashboard-card-box .card-body { transition: 0.35s all; display: flex; flex-direction: column; }

.dashboard-card-box h5 {
  color: #212121;
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.35s all;
}
.dashboard-card-box h2{ transition: 0.35s all;} 
.dashboard-card-box:hover h5, .dashboard-card-box:hover h2 { color: #fff; }
.dashboard-card-box figure {
  width: 100px;
  min-width: 100px;
  margin: 0 0 0 auto;
}
.dashboard-card-box figure svg { fill: #3D1A91; width: 100%; transition: 0.35s all; }
.dashboard-card-box h3 {
  color: #212121;
  font-size: 5rem;
  font-weight: 700;
  margin: 0;
  font-family: "Aptos", sans-serif;
  transition: 0.35s all;
}
.dashboard-card-box:hover h3 { color: #fff; }
.dashboard-card-box:hover figure svg { fill: #fff; }
.rate-time { color: #212121; transition: 0.35s all; white-space: nowrap; }
.dashboard-card-box:hover .rate-time { color: #ffffff; }
.btn.rate-time {
  background: rgba(0,0,0,0.1);
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.35s all;
}
.btn.rate-time:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 5px solid rgba(0,0,0,0.5);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin: 0 -0.4rem 0 0.6rem;
  transition: 0.35s all;
}
.dashboard-card-box:hover .btn.rate-time { background: rgba(255,255,255,0.2); }
.dashboard-card-box:hover .btn.rate-time:after { border-top-color: rgba(255,255,255,0.7); }


.dashboard-card-bottom {
  margin-top: auto;
}
.card-bubble {
  position: absolute;
  height: 16rem;
  width: 16rem;
  background: rgba(0,0,0,0.04);
  border-radius: 50%;
  z-index: -1;
}
.dashboard-card-01 .card-bubble {
  bottom: -17%;
  right: -6%;
}
.dashboard-card-02 .card-bubble {
  top: -42%;
  right: -13%;
}
.dashboard-card-03 .card-bubble {
  top: 13%;
  left: -21%;
}
.dashboard-card-04 .card-bubble { 
  bottom: -24%;
  right: 13%; }
.dashboard-card-05 .card-bubble { 
  top: -40%;
  left: 50%;
  transform: translateX(-50%); 
}
.dashboard-card-06 .card-bubble {
    top: 10%;
    right: -17%;
}

@media only screen and (max-width: 1399px) {
  .dashboard-card-box figure { width: 70px; min-width: 70px; }
  .dashboard-card-box h3 { font-size: 4rem; }
  .dashboard-card-box h5 { font-size: 1.4rem; }
  .dashboard-card-bottom { padding-top: 5rem; }
  .card-bubble { height: 12rem; width: 12rem; }
  
}
@media only screen and (max-width: 1199px) {
  .login-form-box {
    padding: 4.5rem;
  }
  .dashboard-card-box h3 { font-size: 3.2rem; }
  .dashboard-card-box figure { width: 60px; min-width: 60px; }
  .dashboard-card-bottom { padding-top: 3rem; }
}
@media only screen and (max-width: 767px) {
  .login-form-box {
    padding: 4rem;
  }
  .top-title {
    position: static;
    margin-bottom: 1.5rem;
  }
  .dashboard-card-box figure { width: 70px; min-width: 70px; }
}
@media only screen and (max-width: 575px) {
  .login-form-box {
    padding: 3rem;
  }
  .top-search {
    max-width: 100%;
  }
}


.table-search { max-width: 300px; width: 100%; }
.table-search .form-control { background-color: #E0DEE7; border-color: #E0DEE7; color: #303030; transition: 0.35s all; padding-left: 35px; }
.table-search .form-control:focus { border-color: #E0DEE7; background-color: #fff; }
.with-search-ico { position: relative; z-index: 1; }
.with-search-ico:after { content: ""; height: 12px; width: 12px; border: 2px solid #666666; position: absolute; left: 14px; top: 14px; border-radius: 50px; z-index: 3; }
.with-search-ico:before { content: ""; height: 6px; width: 2px; transform: rotate(-45deg); background-color: #666666; position: absolute; left: 24px; top: 22px; border-radius: 50px; z-index: 3; }
.table-add-btn { position: absolute; right: 0; top: 10px; }
.card .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell.MuiDataGrid-cell--textLeft { justify-content: start; }
[data-kt-app-layout="light-sidebar"] div .app-sidebar .menu .menu-item.show > .menu-link .menu-icon i, [data-kt-app-layout="light-sidebar"] div .app-sidebar .menu .menu-item.show > .menu-link .menu-title { color: #F77148 !important; }
[data-kt-app-layout=light-sidebar] .app-sidebar .menu .menu-item.here > .menu-link .menu-arrow:after { background-color:#F77148 !important; }
[data-kt-app-layout=light-sidebar] .app-sidebar .menu .menu-item.here > .menu-link .menu-title { color: #fff; }
.header-user .symbol { border: 1px solid #666666; border-radius: 50%; position: relative; overflow: hidden; }
.MuiTablePagination-root p { margin: 0; }
.MuiTablePagination-root .MuiInputBase-root { margin: 0 1rem; }

.MuiBox-root .MuiSvgIcon-colorPrimary { color: #3D1A91; transition: 0.35s all; }
.MuiBox-root .MuiSvgIcon-colorPrimary:hover { color: #3D1A91; }
.MuiBox-root .MuiSvgIcon-colorError { color: #FA4F4F; transition: 0.35s all; }
.MuiBox-root .MuiSvgIcon-colorError:hover { color: #d9214e; }

@media only screen and (max-width: 767px) {
  .table-add-btn { position: static; }  
}

.cutom-border {
    background-color: #E0DEE7 !important;
    border: 0px solid #ccc !important;
    border-radius: 8px !important;
    outline: none !important;
}

.form-switch .form-check-input {
  cursor: pointer !important;
  /* margin-top: 12px !important; */
}

.css-4iyyks-MuiFormControl-root-MuiTextField-root{
  border: none !important;
}

.token-add-button{
  display: flex;
  align-items: flex-end;
    justify-content: end;
    padding-top: 40px !important;
}

.css-1k51tf5-MuiTooltip-tooltip{
  font-size: 18px !important;
}

pre {
    color:white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.css-8m5rrr-MuiButtonBase-root-MuiChip-root{
  font-family: "Aptos", sans-serif !important;
}

.ql-container{
  font-family: "Aptos", sans-serif !important;
}

.add-golf-image-upload{
  position: relative;
  display: inline-block;
  background-color: rgb(249, 249, 249);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 46px !important;
}

.file-remove-icon{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  margin-right: 14px;
}

.required-icon{
  margin-top: 1.25rem !important;
  color: #fa4f4f !important;
  font-size: 16px;
}

.card-bg-color{
  background-color: white !important
}

.css-15noair-control{
  min-height: 46px !important;
  background-color: #f9f9f9 !important;
}

.css-10judt7-control{
  min-height: 46px !important;
  background-color: #f9f9f9 !important;
}

.css-rvssjw-control{
  min-height: 46px !important;
  background-color: #f9f9f9 !important;
}

.dropdown-content{
  max-height: auto !important;
  overflow-y: auto !important;
  position: absolute;
  z-index: 9999;
  overflow: hidden !important;
}

.css-knqc4i-MuiDialogActions-root{
  justify-content: center !important;
}

.css-1vskg8q{
  justify-content: center !important;
}

.spinner-loader {
  display: flex !important;
  position: fixed !important;
  width: 100% !important;
  height: 100vh !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 1060 !important;
  border-radius: 5px !important;
  margin-top: 23px !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.count-checkbox{
  width: 20px;
  height: 16px;
}

.payment-gateway{
  padding: 10px;
  background-color: #e2e4e4;
  border-radius: 10px;
}

.booking-preference{
  padding: 10px;
  background-color: #e0ddf6;
  border-radius: 5px;
}

.highlight-section{
  background-color: #f0eeee;
  padding: 0px 0px 15px 0px;
  border-radius: 5px;
}