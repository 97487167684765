.css-eqpfi5-MuiAccordionSummary-content{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.css-l0jafl{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.input-border{
    border: 1px solid;
    border-radius: 4px
}

.css-2ulfj5-MuiTypography-root{
    padding-left: 10px !important;
}

.css-1km04pe-MuiCardContent-root{
    padding: 4px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #0000003b !important;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #0000003b !important;
}

.css-1mzerio{
    margin-bottom: 20px !important;
}